import React from 'react';

import Layout from '../../components/Layout';

export default function SegKatastrophenschutz() {
	return (
		<Layout title="SEG / Katastrophenschutz">
			<h2>SEG / Katastrophenschutz</h2>
			<p>Die Schnell-Einsatz-Gruppe (SEG) der BRK Bereitschaft Bad Reichenhall hat folgende Aufgaben:</p>
			<p>
				<ul>
					<li>Leistet schnelle Hilfe im Katastrophenfall</li>
					<li>Unterstützt die hauptamtlichen Rettungskräfte</li>
					<li>Errichtet Behandlungsplätze</li>
					<li>Sorgt für den Aufbau von Sanitätszelten</li>
					<li>Versorgt Verletzte und betreut Angehörige</li>
				</ul>
			</p>
		</Layout>
	);
}
